/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Děti v akci "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1v76zqk css-42e4bw --full pb--60 pt--60" name={"fkwncry0f8q"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/0131c3cb4b24449ab5bb19a3708288ff_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --right el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--border4 pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"7i6y6j88qub"} layout={"l1"} parallax={false} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgb(248, 3, 3); font-weight: bold;\">Dopisy Ježíškovi spuštěny !&nbsp;</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--22" content={"Dopisy prosíme zašlete <br>&nbsp;nejpozději do konce října."}>
              </Subtitle>

              <Text className="text-box fs--16" content={"<span style=\"color: var(--black); font-weight: bold;\">Zaslat prosíme NEDOPORUČENĚ&nbsp;<br>&nbsp;</span><span style=\"color: var(--black);\">Pernerova 444, Pardubice, 530 02.&nbsp;</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 fs--16" content={"<span style=\"font-weight: bold;\">PŘÁNÍ K TISKU ZDE</span>"} url={"https://www.canva.com/design/DAErxDC6ZIg/phpcyCsGTj4X5PbgrhehKw/view?utm_content=DAErxDC6ZIg&utm_campaign=designshare&utm_medium=link&utm_source=editor"} href={"https://www.canva.com/design/DAErxDC6ZIg/phpcyCsGTj4X5PbgrhehKw/view?utm_content=DAErxDC6ZIg&utm_campaign=designshare&utm_medium=link&utm_source=editor"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4 --center" style={{"paddingBottom":37,"paddingTop":33}} name={"q0leqom2vu"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold;\">Vánoční účet<br></span>2402063206 / 2010<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border5 pb--60 pt--60" name={"g8x2yijeb3v"} layout={"l29"} border={"5"}>
          
          <ColumnWrap className="column__flex --center" fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/56900e3e71ec455b942e33bcb34a2f84_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/56900e3e71ec455b942e33bcb34a2f84_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/56900e3e71ec455b942e33bcb34a2f84_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/56900e3e71ec455b942e33bcb34a2f84_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/198c8fcf518446ca8a317f06b52b1ca4_s=660x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/198c8fcf518446ca8a317f06b52b1ca4_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/198c8fcf518446ca8a317f06b52b1ca4_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/198c8fcf518446ca8a317f06b52b1ca4_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/df9c4d8c7b1d46d98751f6280ecda3ea_s=660x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/df9c4d8c7b1d46d98751f6280ecda3ea_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/df9c4d8c7b1d46d98751f6280ecda3ea_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/df9c4d8c7b1d46d98751f6280ecda3ea_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/670604d4fd0b42ab9eb1eb3393bb94ff_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/670604d4fd0b42ab9eb1eb3393bb94ff_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/670604d4fd0b42ab9eb1eb3393bb94ff_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/670604d4fd0b42ab9eb1eb3393bb94ff_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/670604d4fd0b42ab9eb1eb3393bb94ff_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}